import Image from 'next/image'

export default function LogosSection({ heading, logos }) {
    return (
        <section className="flex flex-col gap-4 max-w-7xl mx-auto px-4 py-8">
            <h2 className="text-2xl font-normal text-center text-pink-500">{heading}</h2>
            <div className="flex flex-row justify-center items-center flex-wrap gap-8">
                {(logos || []).map(({ logo }, idx) => (
                    <div key={idx} className="relative w-[125px] h-[125px]">
                        {logo?.data?.attributes?.url && (
                            <Image 
                                src={logo.data.attributes.url}
                                alt={logo.data.attributes.alternativeText || 'Logo'}
                                fill
                                className="object-contain"
                                unoptimized
                            />
                        )}
                    </div>
                ))}
            </div>
        </section>
    )
}
